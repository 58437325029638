import { fresponsive } from "library/fullyResponsive"
import { css } from "styled-components"

const textStyles = {
	h1: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 180px;
		font-style: normal;
		font-weight: 400;
		line-height: 90%; /* 162px */
		letter-spacing: -10.8px;
	`),
	h2: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 120px;
		font-style: normal;
		font-weight: 400;
		line-height: 90%; /* 108px */
		letter-spacing: -7.2px;
	`),
	h3: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 80px;
		font-style: normal;
		font-weight: 400;
		line-height: 90%; /* 72px */
		letter-spacing: -4.8px;
	`),
	h4: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 64px;
		font-style: normal;
		font-weight: 400;
		line-height: 90%; /* 57.6px */
		letter-spacing: -3.84px;
	`),
	h5: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 56px;
		font-style: normal;
		font-weight: 400;
		line-height: 90%; /* 50.4px */
		letter-spacing: -3.36px;
	`),
	h6: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 46px;
		font-style: normal;
		font-weight: 400;
		line-height: 90%; /* 41.4px */
		letter-spacing: -2.3px;
	`),
	h7: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 34px;
		font-style: normal;
		font-weight: 500;
		line-height: 90%; /* 30.6px */
		letter-spacing: -1.7px;
	`),
	h8: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 90%; /* 21.6px */
		letter-spacing: -0.96px;
	`),
	h9: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 90%; /* 16.2px */
		letter-spacing: -0.54px;
	`),
	banner1: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 128px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -2.56px;
		text-transform: uppercase;
	`),
	banner2: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 64px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -1.28px;
		text-transform: uppercase;
	`),
	kick: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 10px */
		letter-spacing: 0.2px;
		text-transform: uppercase;
	`),
	kicker1: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 24px */
		text-transform: uppercase;
	`),
	kicker2: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 20px */
		text-transform: uppercase;
	`),
	kicker3: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 14px */
		text-transform: uppercase;
	`),
	kicker4: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 10px */
		letter-spacing: 0%;
		text-transform: uppercase;
	`),
	button1: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 40px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 40px */
		text-transform: uppercase;
	`),
	button2: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 24px */
		text-transform: uppercase;
	`),
	button3: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 18px */
		text-transform: uppercase;
	`),

	button4: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 16px */
		text-transform: uppercase;
	`),
	button5: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 14px */
		text-transform: uppercase;
	`),
	body1: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 28px */
		letter-spacing: -0.2px;
	`),
	body2: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 22.4px */
		letter-spacing: -0.16px;
	`),
	bodyR: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
		letter-spacing: -0.16px;
`),
	body3: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 18.2px */
		letter-spacing: -0.13px;
	`),
	product1: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 12px */
		letter-spacing: -0.48px;
	`),
	product2: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 12px */
		letter-spacing: -0.48px;
	`),
	product3: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 12px */
		letter-spacing: -0.48px;
		text-transform: uppercase;
	`),
	product4: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 10px */
		letter-spacing: -0.4px;
		text-transform: uppercase;
	`),
	product5: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 8px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 10px */
		letter-spacing: -0.4px;
		text-transform: uppercase;
	`),
	productBody1: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 14px */
		letter-spacing: -0.1px;
	`),
	blogQuote: fresponsive(css`
		font-family: Fraktion, sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 25.2px */
		letter-spacing: -0.18px;
	`),
	blogH1: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 56px;
		font-style: normal;
		font-weight: 400;
		line-height: 105%; /* 58.8px */
		letter-spacing: -3.36px;
	`),
	blogH2: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 46px;
		font-style: normal;
		font-weight: 400;
		line-height: 105%; /* 48.3px */
		letter-spacing: -2.3px;
	`),
	blogH2Alt: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 40px;
		font-style:  normal;
		font-weight: 400;
		line-height: 105%; /* 48.3px */
		letter-spacing: -2.3px;
	`),
	blogH3: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 34px;
		font-style: normal;
		font-weight: 500;
		line-height: 105%; /* 35.7px */
		letter-spacing: -1.7px;
	`),
	blogH4: fresponsive(css`
		font-family: Slussen, sans-serif;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 105%; /* 25.2px */
		letter-spacing: -0.96px;
	`),
}

export const strokeText = css`
	/* styled doesn't prefix this property yet */
	/* stylelint-disable property-no-vendor-prefix */
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	background-size: 100%;
	background-clip: text;
	-webkit-text-stroke-width: 0.07vw;
`

export const strokeTextTransparent = css`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  -webkit-text-stroke-width: 0.07vw;
`

export const transparentText = css`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
`

export const clampText = (lines: number) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
`

export default textStyles
