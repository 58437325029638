import * as Dialog from "@radix-ui/react-dialog"
import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import TextPlugin from "gsap/TextPlugin"
import MobileBoxSVG from "images/modal/MobileBox.svg"
import BoxSVG from "images/modal/ModalBox.svg"
import TabletBoxSVG from "images/modal/TabletBox.svg"
import TypedEventEmitter from "library/TypedEventEmitter"
import UniversalImage from "library/UniversalImage"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useMedia from "library/useMedia"
import { useCallback, useEffect, useRef, useState } from "react"
import Static from "sections/home/01-Hero/Static"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

import ContactForm from "./Form"
import Close from "./buttons/Close"

gsap.registerPlugin(TextPlugin)

const events = new TypedEventEmitter<{
	sync: []
}>()

export const useContactModal = (): [
	open: boolean,
	setOpen: (open: boolean) => void,
] => {
	const [value, setValue] = useState<boolean>(false)

	useEffect(() => {
		const onSync = () => {
			const newValue = new URLSearchParams(window.location.search).has(
				"contactOpen",
			)

			setValue(newValue)
		}

		onSync()

		events.addEventListener("sync", onSync)
		return () => {
			events.removeEventListener("sync", onSync)
		}
	}, [])

	const externalSetValue = useCallback((newValue: boolean) => {
		window.history.replaceState(
			{},
			"",
			`${window.location.pathname}${newValue ? "?contactOpen" : ""}`,
		)
		events.dispatchEvent("sync")
	}, [])

	return [value, externalSetValue]
}

export default function ContactModal() {
	const [open, setOpen] = useContactModal()
	const rightRef = useRef<HTMLDivElement | null>(null)
	const titleRef = useRef<HTMLHeadingElement | null>(null)
	const descRef = useRef<HTMLParagraphElement | null>(null)

	const image: Queries.ContactModalQuery = useStaticQuery(graphql`
		query ContactModal {
			file(relativePath: { eq: "modal/Map.webp" }) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`)

	const onSubmissionComplete = () => {
		const tl = gsap.timeline()

		tl.to(
			rightRef.current,
			{
				autoAlpha: 0,
			},
			0,
		)

		tl.set(
			rightRef.current,
			{
				display: "none",
			},
			1,
		)

		tl.to(
			[titleRef.current, descRef.current],
			{
				opacity: 0,
				duration: 0.5,
			},
			0,
		)

		tl.set(
			titleRef.current,
			{
				text: "Thank You.",
			},
			0.5,
		)

		tl.set(
			descRef.current,
			{
				text: "We will reach out to you shortly.",
			},
			0.5,
		)

		tl.to(
			[titleRef.current, descRef.current],
			{
				opacity: 1,
				duration: 0.5,
			},
			0.5,
		)
	}

	return (
		<Dialog.Root
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen)
			}}
		>
			<Overlay>
				<StaticOverlay />
			</Overlay>
			<Content onOpenAutoFocus={(e) => e.preventDefault()}>
				<Background
					alt=""
					src={useMedia(BoxSVG, BoxSVG, TabletBoxSVG, MobileBoxSVG)}
				/>
				<ContentInner>
					<BackgroundMap
						image={image.file?.childImageSharp?.gatsbyImageData}
						alt="a faint map graphic"
					/>
					<Left>
						<Title ref={titleRef}>Get in Touch</Title>
						<Description ref={descRef}>
							If you&apos;re interested in bringing the future of air support to
							your agency, fill out the form to the right.
						</Description>
					</Left>
					<Right ref={rightRef}>
						<ContactForm onComplete={onSubmissionComplete} />
					</Right>
					<Dialog.Close asChild>
						<Close type="button" />
					</Dialog.Close>
				</ContentInner>
			</Content>
		</Dialog.Root>
	)
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const slideIn = keyframes`
  /* we need to start on screen to prevent any scroll-to-element from happening */
  0% {
    opacity: 0;
    translate: 0 0;
  }
  1% {
    opacity:  0;
    translate: 0 -100vh;
  }
  2% {
    opacity:  1;
    translate: 0 -100vh;
  }
  100% {
    opacity:  1;
    translate: 0 0
  }
`

const slideOut = keyframes`
  from {
    translate: 0 0
  }
  to {
    translate: 0 -100vh
  }
`

const Overlay = styled(Dialog.Overlay)`
	position: fixed;
	inset: 0;
	z-index: 10;

	&[data-state="open"] {
		animation: ${fadeIn} 0.5s ease-out;
	}

	&[data-state="closed"] {
		animation: ${fadeOut} 0.5s ease-out;
	}
`

const StaticOverlay = styled(Static)`
	position: absolute;
	inset: 0;
	opacity: 0.5;
	z-index: 1;
`

const Content = styled(Dialog.Content)`
	${fresponsive(css`
		position: fixed;
		z-index: 10;
		width: 1360px;
		height: 720px;
		left: calc(50% - calc(1360px / 2));
		top: calc(50% - calc(720px / 2));
		color: ${colors.white};
		border-radius: 24px;
		overflow: hidden;
		padding: 122px 50px 50px 110px;
	`)}

	${ftablet(css`
		--height: min(90vh, 1286px);

		/* stylelint-disable plugin/no-unsupported-browser-features */
		@supports (height: min(90dvh, 1286px)) {
			--height: min(90dvh, 1286px);
		}

		width: 946px;
		height: var(--height);
		left: calc(50% - calc(946px / 2));
		top: calc(50% - calc(var(--height) / 2));
		padding: 110px 97px 97px 90px;
	`)}

  ${fmobile(css`
		--height: min(90vh, 880px);

		@supports (height: min(90dvh, 880px)) {
			--height: min(90dvh, 880px);
		}

		width: 343px;
		height: var(--height);
		left: calc(50% - calc(343px / 2));
		top: calc(50% - calc(var(--height) / 2));
		gap: 93px;
		padding: 55px 30px 30px 28px;
	`)}

  &[data-state="open"] {
		animation: ${slideIn} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
	}

	&[data-state="closed"] {
		animation: ${slideOut} 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22);
	}
`

const Background = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-height: none;
	z-index: -1;
	display: block;
`

const ContentInner = styled.div`
	width: 100%;
	height: 100%;

	&::-webkit-scrollbar {
		display: none;
	}

	${fresponsive(css`
		display: flex;
		justify-content: space-between;
	`)}

	${ftablet(css`
		flex-direction: column;
		align-items: stretch;
		overflow-y: auto;
	`)}

  ${fmobile(css`
		flex-direction: column;
		align-items: stretch;
		overflow-y: auto;
	`)}
`

const BackgroundMap = styled(UniversalImage)`
	${fresponsive(css`
		position: absolute;
		inset: 0;
		z-index: -1;
		top: 50px;
		left: 44px;
		width: 644px;
		height: 597px;
		object-fit: contain;
	`)}

	${ftablet(css`
		width: 895px;
		height: 604px;
	`)}

  ${fmobile(css`
		top: 16px;
		left: 0;
		width: 100%;
		height: 395px;
	`)}
`

const Left = styled.div`
	${fmobile(css`
		margin-bottom: 93px;
	`)}
`

const Title = styled(Dialog.Title)`
	${textStyles.h2};
	${fresponsive(css`
		width: 533px;
	`)}

	${ftablet(css`
		width: 700px;
	`)}

  ${fmobile(css`
		width: 225px;
		${textStyles.h3}
	`)}
`

const Description = styled(Dialog.Description)`
	${textStyles.body2};
	${fresponsive(css`
		width: 391px;
		margin-left: 20px;
		margin-top: 28px;
	`)}
	${fmobile(css`
		width: 231px;
		margin-top: 18px;
		margin-left: 8px;
		${textStyles.body3}
	`)}
`

const Right = styled.div`
	${fresponsive(css`
		width: 446px;
	`)}
	${ftablet(css`
		width: auto;
	`)}
  ${fmobile(css`
		width: auto;
	`)}
`
