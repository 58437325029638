import gsap from "gsap"
import { usePageTransition } from "library/Loader/TransitionUtils"
import { useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import textStyles from "styles/text"

import { ReactComponent as ArrowsSVG } from "images/global/LaunchArrows.svg"
import EdgeSVG from "images/global/TransitionEdge.svg"
import { fmobile, fresponsive } from "library/fullyResponsive"
import { getResponsivePixels } from "library/viewportUtils"
import colors from "styles/colors"

export default function PageTransition() {
	const wrapperRef = useRef<HTMLDivElement>(null)

	const slideIn = () => {
		gsap.fromTo(
			wrapperRef.current,
			{
				scale: 0.8,
			},
			{
				scale: 1,
				y: 0,
				ease: "power3.out",
			},
		)
	}

	const slideOut = () => {
		gsap.fromTo(
			wrapperRef.current,
			{
				y: 0,
				scale: 1,
			},
			{
				delay: 0.5,
				scale: 0.8,
				y: -window.innerHeight - getResponsivePixels(40),
				ease: "power3.in",
			},
		)
	}

	usePageTransition("slide", {
		in: slideIn,
		out: slideOut,
		inDuration: 0.5,
		outDuration: 1,
	})

	return (
		<Wrapper ref={wrapperRef}>
			<TopEdge />
			<BottomEdge />
			<Arrows />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	transform: translateY(120vh);
	background-color: ${colors.black};
	z-index: 100;
	pointer-events: none;
	display: grid;
	place-items: center;
	${textStyles.h1};
`

const TopEdge = styled.img.attrs({ src: EdgeSVG, alt: "" })`
	position: absolute;
	bottom: calc(100% - 1px);
	width: 100%;
	object-fit: cover;
	${fresponsive(css`
		border-radius: 24px 24px 0 0;
		height: 40px;
	`)}

	${fmobile(css`
		height: 20px;
	`)}
`

const BottomEdge = styled(TopEdge)`
	top: calc(100% - 1px);
	bottom: auto;
	transform: rotate(180deg);
`

const arrowsAnimation = keyframes`
  0% {
    opacity: 1;
  }
  16.5% {
    opacity: 1;
  }
  16.501% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.3
  }
`

const Arrows = styled(ArrowsSVG)`
	path {
		animation: ${arrowsAnimation} 1200ms infinite;
	}

	path.path2 {
		animation-delay: 200ms;
	}

	path.path3 {
		animation-delay: 400ms;
	}

	${fresponsive(css`
		width: 24px;
	`)}
`
