import * as Form from "@radix-ui/react-form"
import { isBrowser } from "library/deviceDetection"
import { fmobile, fresponsive } from "library/fullyResponsive"
import { phone as phoneUtil } from "phone"
import { useEffect, useRef, useState } from "react"
import { PhoneInput } from "react-international-phone"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function PhoneNumber() {
	const [phone, setPhone] = useState("")
	const inputRef = useRef<HTMLInputElement>(null)
	const enableConstantChecks = useRef(false)
	const [fieldIsEnabled, setFieldIsEnabled] = useState(false)

	useEffect(() => {
		if (inputRef.current && fieldIsEnabled) {
			const hasValue = phone.length > 6

			inputRef.current.value = hasValue ? phone : ""

			if (enableConstantChecks.current)
				inputRef.current.dispatchEvent(new Event("change", { bubbles: true }))
		}
	}, [fieldIsEnabled, phone])

	/**
	 * detect US/Canada phone numbers that have been entered without the country code
	 * and add the country code for them
	 */
	useEffect(() => {
		if (!fieldIsEnabled || !isBrowser) return
		const input = document.querySelector(".react-international-phone-input")

		const onBlur = () => {
			// we don't want to trigger validation before the user has typed at all
			// once the user types a number, we start triggering validation on input
			enableConstantChecks.current = true

			if (input && input instanceof HTMLInputElement) {
				const v = input.value

				if (
					!phoneUtil(v).isValid && // not valid
					!v.includes("+1") && // doesn't include US country code
					phoneUtil(v.replace("+", "")).isValid // is a valid US number
				) {
					const newVal = input.value.replace("+", "+1")
					setPhone(newVal)
					input.value = newVal
				}
			}
		}

		input?.addEventListener("blur", onBlur)
		return () => input?.removeEventListener("blur", onBlur)
	}, [fieldIsEnabled])

	useEffect(() => {
		setTimeout(() => {
			setFieldIsEnabled(true)
		}, 2000)
	}, [])

	return (
		<>
			<Hidden required aria-hidden asChild>
				<Input ref={inputRef} />
			</Hidden>
			<StyledPhone
				phone={phone}
				value={phone}
				onChange={setPhone}
				disabled={!fieldIsEnabled}
			/>
		</>
	)
}

const Hidden = styled(Form.Control)`
	visibility: hidden;
	position: absolute;
`

const Input = styled.input`
	&[data-invalid] + div > * {
		border-color: ${colors.red01};
	}
`

const StyledPhone = styled(PhoneInput)<{ phone: string }>`
	/* stylelint-disable selector-class-pattern */

	/* most outer container styles */
	color: ${colors.grey04};
	${textStyles.body2};
	display: grid;
	position: relative;
	height: min-content;
	width: 100%;

	* {
		outline: none;
		transition: 0.2s ease-in-out;
		transition-property: border-color, color, background;
	}

	${fresponsive(css`
		gap: 4px;
		grid-template-columns: 88px 1fr;
	`)}

	/* text input box */
  .react-international-phone-input {
		border-bottom: 1px solid ${colors.grey04};

		${fresponsive(css`
			padding-bottom: 14px;
		`)}

		${fmobile(css`
			padding-right: 50px;
			width: 100%;
		`)}

    :hover {
			border-color: ${colors.grey03};
			color: ${colors.white};
		}
	}

	.react-international-phone-input:focus {
		border-color: ${colors.grey03};
		color: ${colors.white};
	}

	.react-international-phone-country-selector-button:focus {
		border-color: ${colors.grey03};
		color: ${colors.white};
	}

	/* flag button outer wrapper */
	.react-international-phone-country-selector {
		border-bottom: 1px solid ${colors.grey04};
	}

	/* flag button html button element */
	/* stylelint-disable no-descending-specificity */
	.react-international-phone-country-selector-button {
		width: 100%;
		height: 100%;
		cursor: pointer;

		${fresponsive(css`
			:focus {
				outline: 1px solid black;
			}

			:hover {
				border-color: ${colors.grey03};
			}
		`)}
	}

	/* flag button inner wrapper */
	.react-international-phone-country-selector-button__button-content {
		${fresponsive(css`
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			transform: translate(-12px, -6px);
		`)}
	}

	/* flag images */
	.react-international-phone-flag-emoji {
		${fresponsive(css`
			width: 32px;
			height: auto;
		`)}
	}

	/* dropdown container */
	.react-international-phone-country-selector-dropdown {
		border-bottom: 1px solid ${colors.grey04};

		&::-webkit-scrollbar {
			display: none;
		}

		${fresponsive(css`
			position: absolute;
			z-index: 100;
			left: 0;
			right: 0;
			top: calc(100% + 2px);
			color: black;
			max-height: 400px;
			overflow-y: auto;
			border-radius: 12px;
			background: linear-gradient(
				214deg,
				rgb(0 0 0 / 80%) -34.53%,
				rgb(30 30 30 / 51%) 79.89%
			);
			box-shadow: 0 10px 18px 0 rgb(0 0 0 / 10%);
			backdrop-filter: blur(5px);
		`)}
	}

	/* dropdown arrow */
	.react-international-phone-country-selector-button__dropdown-arrow {
		width: 16px;
		height: 16px;
		position: relative;
		transition: transform 0.2s ease-in-out;

		::before {
			content: "";
			left: 8px;
			bottom: 4px;
			height: 8px;
			width: 2px;
			position: absolute;
			transform-origin: bottom left;
			transform: rotate(-45deg);
		}

		::after {
			content: "";
			right: 8px;
			bottom: 4px;
			height: 8px;
			width: 2px;
			position: absolute;
			transform-origin: bottom right;
			transform: rotate(45deg);
		}
	}

	/* dropdown arrow when active */
	.react-international-phone-country-selector-button__dropdown-arrow--active {
		transform: rotate(180deg);
	}

	/* dropdown option */
	.react-international-phone-country-selector-dropdown__list-item {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 10px 20px;
		color: ${colors.grey04};
	}
`
