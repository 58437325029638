import * as Form from "@radix-ui/react-form"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import Primary from "components/buttons/Primary"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import { phone } from "phone"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

import useMedia from "library/useMedia"
import CountriesDropdown from "./CountriesDropdown"
import Input from "./Input"
import PhoneNumber from "./PhoneNumber"
import TextArea from "./TextArea"

const PORTAL_ID = "39655947"
const FORM_ID = "bd8597c2-6eb1-42ce-ba80-a7fd97864b29"

export default function ContactForm({
	onComplete,
}: {
	onComplete?: () => void
}) {
	const phoneName = useMedia(
		"Phone Number",
		"Phone Number",
		"Phone Number",
		"Phone",
	)
	return (
		<FormRoot
			onSubmit={(e) => {
				e.preventDefault()
				const url = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`

				const data = new FormData(e.currentTarget)

				const submission = {
					fields: [...data.entries()].map(([name, value]) => ({
						name,
						value,
					})),
				}

				fetch(url, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(submission),
				})
					.then(() => {
						if (onComplete) {
							onComplete()
						}

						return null
					})
					.catch((error: Error) => {
						console.error(error)
					})
			}}
		>
			<FormField name="firstname">
				<Form.Control asChild>
					<Input placeholder="John" required />
				</Form.Control>
				<FormMessage match="valueMissing">
					Please enter your first name
				</FormMessage>
				<Label>First Name</Label>
			</FormField>

			<FormField name="lastname">
				<Form.Control asChild>
					<Input placeholder="Smith" required />
				</Form.Control>
				<FormMessage match="valueMissing">
					Please enter your last name
				</FormMessage>
				<Label>Last Name</Label>
			</FormField>

			<FormField name="email">
				<Form.Control asChild>
					<Input placeholder="john@email.com" required type="email" />
				</Form.Control>
				<FormMessage match="valueMissing">Please enter your email</FormMessage>
				<FormMessage match="typeMismatch">
					Please enter a valid email
				</FormMessage>
				{/* forbid dotless domains */}
				<FormMessage match={(v) => !/@.*\./.test(v)}>
					Please enter a valid email
				</FormMessage>
				<Label>Email</Label>
			</FormField>

			<FormField name="phone">
				<PhoneNumber />
				<FormMessage match="valueMissing">
					Please enter a phone number
				</FormMessage>
				<FormMessage match={(v) => !phone(v).isValid}>
					Please enter a valid phone number
				</FormMessage>
				<Label>{phoneName}</Label>
			</FormField>

			<FormField name="company">
				<Form.Control asChild>
					<Input placeholder="LAPD" required />
				</Form.Control>
				<FormMessage match="valueMissing">
					Please enter your organization
				</FormMessage>
				<Label>Organization</Label>
			</FormField>

			<FormField name="jobtitle">
				<Form.Control asChild>
					<Input placeholder="Chief of Police" required />
				</Form.Control>
				<FormMessage match="valueMissing">
					Please enter your Job Title
				</FormMessage>
				<Label>Job Title</Label>
			</FormField>

			<Form.Field name="region">
				<VisuallyHidden.Root>
					<Form.Label>Country / Region</Form.Label>
				</VisuallyHidden.Root>
				<CountriesDropdown />
				<FormMessage match="valueMissing">Please Select A Region</FormMessage>
			</Form.Field>

			<Form.Field name="message">
				<VisuallyHidden.Root>
					<Form.Label>Write a message</Form.Label>
				</VisuallyHidden.Root>
				<Form.Control asChild>
					<TextArea placeholder="Write a message" required />
				</Form.Control>
				<FormMessage match="valueMissing">Please enter a message</FormMessage>
			</Form.Field>

			<Form.Submit asChild>
				<FullWidthPrimary type="submit">Submit</FullWidthPrimary>
			</Form.Submit>
		</FormRoot>
	)
}

const FormRoot = styled(Form.Root)`
	${fresponsive(css`
		display: grid;
		gap: 14px;
		height: 100%;
		grid-template-rows: auto auto auto auto auto auto auto 1fr;
	`)}

	${ftablet(css`
		gap: 24px;
	`)}
  ${fmobile(css`
		gap: 16px;
		overflow: clip;
		width: 100%;
	`)}
`

const FormMessage = styled(Form.Message)`
	${textStyles.kicker4};
	color: ${colors.red01};
	display: block;
	height: 0;
	${fresponsive(css`
		translate: 0 2px;
	`)}
`

const FullWidthPrimary = styled(Primary)`
	width: 100%;
	place-self: end end;

	${ftablet(css`
		margin-top: calc(60px - 24px);
	`)}
	${fmobile(css`
		width: 99%;
		margin-top: 4px;
		place-self: end center;
	`)}
`

const Label = styled(Form.Label)`
	${textStyles.kicker4};
	color: ${colors.grey04};
	pointer-events: none;
	position: absolute;
	${fresponsive(css`
		right: 0;
		top: 8px;
	`)}

	${ftablet(css`
		top: 4px;
	`)}

  ${fmobile(css`
		top: 6px;
	`)}
`

const FormField = styled(Form.Field)`
	position: relative;
`
